import { useEffect, useState } from 'react';
import {
  createEmbeddingContext,
  EmbeddingEvents,
  EventListener,
  ExperienceFrameMetadata,
} from 'amazon-quicksight-embedding-sdk';
import { connect } from 'react-redux';

import { sendCustomEvent } from '@Analytics';
import { useFetchData } from '@CustomHooks';
import { bopsApi } from '@Network';
import { RootState } from '@Store';
import QuicksightTopic from './quicksightTopic';

const QuicksightTopicContainer = ({ isTopicEnabled }) => {
  if (!isTopicEnabled) return null;

  const { data, isLoading, error } = useFetchData<string>(bopsApi.getDashboardSearchBar);
  const [dashboardError, setDashboardError] = useState();
  const frameId = 'my-topic-frame';

  useEffect(() => {
    if (!data) return;

    const buildTopic = async () => {
      const onMessageHandler: EventListener = (
        messageEvent: EmbeddingEvents,
        experienceMetadata?: ExperienceFrameMetadata,
      ) => {
        switch (messageEvent.eventName) {
          case 'Q_SEARCH_OPENED':
            sendCustomEvent('Q_SEARCH_OPENED');
            break;

          default:
            break;
        }
      };

      const embeddingContext = await createEmbeddingContext();
      await embeddingContext.embedQSearchBar(
        {
          url: data,
          container: `#${frameId}`,
          withIframePlaceholder: true,
          // onChange: (changeEvent, metadata) => { },
        },
        { onMessage: onMessageHandler },
      );
    };

    buildTopic().catch((e) => {
      setDashboardError(e);
    });
  }, [data]);

  if (isLoading) return null;
  if (error || dashboardError) {
    console.log(error || dashboardError);
    return null;
  }

  return <QuicksightTopic frameId={frameId} />;
};

const mapStateToProps = (state: RootState) => {
  const { client } = state;
  const { configuration } = client;
  const { isTopicEnabled } = configuration;
  return { isTopicEnabled };
};

export default connect(mapStateToProps)(QuicksightTopicContainer);
