import { useEffect, useState } from 'react';

import { sendErrorEvent } from '@Analytics';

const useFetchData = <Type>(promiseFunction: (any) => Promise<Type>, params?: any, skip?: boolean) => {
  const [data, setData] = useState<Type>();
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState<any | undefined>(undefined);

  const fetchData = async (callParams?: any) => {
    try {
      const response = await promiseFunction(callParams);
      setData(response);
    } catch (_error: any) {
      sendErrorEvent(`Error: ${_error.message} - URL: ${_error.config?.baseURL}`);
      setError(_error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (skip) return;
    fetchData(params);
  }, [params]);

  const refetch = async (newParams?: any) => {
    setIsFetching(true);
    await fetchData(newParams);
    setIsFetching(false);
  };

  return {
    data,
    isLoading,
    isFetching,
    error,
    refetch,
  };
};

export default useFetchData;
