import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { faScrewdriverWrench, faTruckFront, faUsers } from '@fortawesome/free-solid-svg-icons';

import {
  admin_icon,
  credential_icon,
  dashboard_icon,
  documentation_icon,
  IconDashboard,
  insight_icon,
  mfa_icon,
  setting_icon,
  suppliers_icon,
  toolkit_icon,
  tools_icon,
  tutorial_icon,
  users_icon,
} from '@Assets';
import { Claim, ClientConfiguration, Permission } from '@Models';
import { AppDispatch, MiscellaneousActions, RootState } from '@Store';
import config from '../../config';
import { ConfigurationKey } from '../WithValidConfiguration/withValidConfiguration';
import Sidebar, { MenuItemProps } from './sidebar';
import { StyledIcon } from './sidebar.styles';

interface SidebarContainerProps {
  permissions: string[];
  configuration?: ClientConfiguration;
  refreshDashboard: () => void;
  account: string;
}

const SidebarContainer = ({ permissions, configuration, refreshDashboard, account }: SidebarContainerProps) => {
  const { logout } = useAuth0();
  const { dataRepository, externalPageLinks, resources, dashboards, logo } = configuration || {};
  const { t, i18n } = useTranslation();
  const [currentLanguage, setLanguage] = useState(i18n.language);

  useEffect(() => {
    if (currentLanguage !== i18n.language) {
      // This is here to force a refresh when language is updated
      // otherwise sidebar text stays in english
      setLanguage(i18n.language);
    }
  }, [i18n.language]);

  const dashboardMenuItems: ((MenuItemProps & { hideWhenDisabled?: boolean }) | null)[] = [
    ...(dashboards
      ? [
          {
            label: t('Sidebar.Monitor'),
            svg: dashboard_icon,
            defaultOpen: true,
            submenu: dashboards.map((dashboard) => ({
              label: dashboard.name,
              svg: dashboard_icon,
              link: `/monitor/${dashboard.name}`,
              enabled: true,
            })),
          },
        ]
      : [
          {
            label: t('Sidebar.Monitor'),
            svg: dashboard_icon,
            link: `/monitor`,
            enabled: true,
          },
        ]),
  ];

  const rawMenuItems: ((MenuItemProps & { hideWhenDisabled?: boolean }) | null)[] = config.isGs1Flavor
    ? [
        configuration?.connectorTypes && !configuration?.hasMultipleAccounts
          ? {
              label: t('Sidebar.Credentials'),
              svg: credential_icon,
              link: '/credentials',
              hideWhenDisabled: true,
            }
          : null,
        {
          label: 'Admin',
          svg: admin_icon,
          submenu: [
            {
              permission: Permission.ManageSuppliers,
              label: t('Sidebar.Suppliers'),
              svg: suppliers_icon,
              link: '/suppliers',
              hideWhenDisabled: true,
              enabled: true,
            },
            {
              permission: Permission.ManageUsers,
              label: t('Sidebar.Users'),
              svg: users_icon,
              link: '/users',
              hideWhenDisabled: true,
              enabled: true,
            },
          ],
        },
        {
          label: t('Sidebar.Tools'),
          svg: tools_icon,
          submenu: [
            {
              label: t('Sidebar.Gpc'),
              externalLink: 'https://gpc-browser.gs1.org',
              enabled: true,
            },
            {
              label: t('Sidebar.Syncfonia'),
              externalLink: 'https://gs1mexicoprod.riversand.com/',
              enabled: true,
            },
            {
              label: t('Sidebar.Verified'),
              externalLink: 'https://www.gs1mexico.org/verified',
              enabled: true,
            },
          ],
        },
      ]
    : [
        configuration?.connectorTypes && !configuration?.hasMultipleAccounts
          ? {
              label: t('Sidebar.Credentials'),
              link: '/credentials',
              svg: credential_icon,
            }
          : null,
        {
          permission: Permission.NavRecommendation,
          label: t('Sidebar.Main'),
          icon: <IconDashboard />,
          link: '/recommendation',
          hideWhenDisabled: true,
        },
        {
          label: 'MFA',
          svg: mfa_icon,
          link: '/mfa',
          hideWhenDisabled: true,
          hide: !configuration?.phones,
        },
        {
          label: 'Admin',
          svg: admin_icon,
          submenu: [
            {
              permission: Permission.ManageUsers,
              label: t('Sidebar.Users'),
              svg: users_icon,
              link: '/users',
              enabled: true,
              hideWhenDisabled: true,
            },
            {
              permission: Permission.ManualUpload,
              label: t('Sidebar.ManualUpload'),
              svg: users_icon,
              link: '/manualUpload',
              enabled: true,
              hideWhenDisabled: true,
            },
          ],
        },
        {
          configurationKey: 'configurationLinksId' as ConfigurationKey,
          label: t('Sidebar.Configure'),
          svg: setting_icon,
          link: '/configure',
        },
        {
          label: t('Sidebar.Toolkit'),
          svg: toolkit_icon,
          submenu: [
            {
              label: t('Sidebar.Insights'),
              externalLink: 'https://www.bops.ai/resources-hub',
              enabled: true,
              svg: insight_icon,
            },
            ...(dataRepository
              ? [
                  {
                    label: t('Sidebar.Documentation'),
                    svg: documentation_icon,
                    enabled: !!dataRepository,
                    link: dataRepository ? `/documentation/${dataRepository}` : `/`,
                  },
                ]
              : []),
            ...(resources
              ? [
                  {
                    label: t('Sidebar.TrainingVideos'),
                    enabled: !!resources,
                    type: 'videos',
                    svg: tutorial_icon,
                  },
                ]
              : []),
          ],
        },
      ];

  const menuItems: MenuItemProps[] = dashboardMenuItems
    .concat(rawMenuItems)
    .map((item) => {
      if (!item) return null;

      const hasEnabledProperty = Object.prototype.hasOwnProperty.call(item, 'enabled');

      if (hasEnabledProperty) return item;

      const { permission, configurationKey, hideWhenDisabled } = item || {};

      const needValidation = permission || configurationKey;
      const validPermission = permission && permissions.includes(permission);
      const validConfiguration = configurationKey && configuration?.[configurationKey];

      const enabled = !!(!needValidation || validPermission || validConfiguration);

      // if has submenu check if have permissions to see it
      if (item.submenu) {
        const hasValidSubmenu = item.submenu.some(
          (submenu) => !submenu.permission || permissions.includes(submenu.permission),
        );
        if (!hasValidSubmenu) {
          return null;
        }
      }
      if ((hideWhenDisabled && !enabled) || item.hide) {
        return null;
      }

      return { ...item, enabled };
    })
    .flatMap((obj) => (obj ? [obj] : []));

  const onLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <Sidebar
      onLogout={onLogout}
      permissions={permissions}
      menuItems={menuItems}
      showResources={!!resources}
      dataRepository={dataRepository}
      account={account}
      logo={logo}
    />
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  const { updateValue: dispatchUpdateValue } = MiscellaneousActions;

  return {
    refreshDashboard: () => dispatch(dispatchUpdateValue({ dashboardRefresh: true })),
  };
};

const mapStateToProps = (state: RootState) => {
  const { authorization, client } = state;
  const { permissions, claims } = authorization;
  const account = claims && claims[Claim.Account];

  return { permissions, configuration: client?.configuration, account };
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarContainer);
