import React from 'react';
import { createRoot } from 'react-dom/client';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';

import { initializeAwsRum } from '@Analytics';
import { persistor, store } from '@Store';
import { bopsTheme } from '@Themes';
import App from './app';
import { initializeSentry, reportWebVitals } from './Initialization';
import { Auth0WithHistoryProvider } from './Providers';

import './Initialization/i18Next';

initializeAwsRum();
initializeSentry();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Auth0WithHistoryProvider>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ProSidebarProvider>
            <ThemeProvider theme={bopsTheme}>
              <App />
            </ThemeProvider>
          </ProSidebarProvider>
        </PersistGate>
      </ReduxProvider>
    </Auth0WithHistoryProvider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
