import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ErrorMessage, Loading, NotFound } from '@Components';
import { useFetchData } from '@CustomHooks';
import { Claim, Options, Permission } from '@Models';
import { bopsApi } from '@Network';
import { RootState } from '@Store';
import ManualUpload from './manualUpload';

interface ManualUploadProps {
  hasPermission: boolean;
  manualUpload?: string[];
}

const ManualUploadContainer = ({ hasPermission, manualUpload }: ManualUploadProps) => {
  const { t } = useTranslation();

  const { data: retailers, isLoading: isLoadingRetailers, error: errorRetailers } = useFetchData(bopsApi.getRetailers);

  const selectedRetailer = useMemo(() => retailers?.[0]?.value, [retailers]);

  const {
    data: clients,
    isLoading: isLoadingManualUpload,
    error: errorManualUpload,
    refetch,
    isFetching,
  } = useFetchData(bopsApi.getManualUpload, selectedRetailer, !selectedRetailer);

  if (!isLoadingRetailers && errorRetailers) {
    return <ErrorMessage internalMessage="Retailers not found" />;
  }

  if (isLoadingRetailers || isLoadingManualUpload) return <Loading title={t('ManualUpload.Loading')} />;

  if (!clients) return <ErrorMessage internalMessage="Manual Upload not found" />;
  if (!hasPermission) return <NotFound />;
  if (!retailers) return <ErrorMessage internalMessage="Retailers not found" />;
  if (errorManualUpload) {
    return <ErrorMessage internalMessage={`Unable to get configuration: ${errorManualUpload}`} />;
  }

  return (
    <ManualUpload
      clients={clients}
      refetch={refetch}
      isFetching={isFetching}
      retailers={retailers}
      selectedRetailer={{ value: retailers?.[0]?.value, label: retailers?.[0]?.label }}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  const { authorization } = state;
  const { permissions, claims } = authorization;
  const manualUpload = claims && claims[Claim.ManualUpload];
  const hasPermission = permissions.includes(Permission.ManualUpload);

  return { hasPermission, manualUpload };
};

export default connect(mapStateToProps)(ManualUploadContainer);
